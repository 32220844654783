<script>
import Layout from "../../layouts/main.vue";

export default {
    data() {
    },
    props: {
    },
    components: {
        Layout
    },
    computed: {
    }
};
</script>
        
<template>
    <Layout>
        <div class="text-center mt-5 pt-5">
            <img src="@/assets/images/logo-light.png" alt="Busycast" class="mt-5 mb-5" />
            <h1>Thank you!</h1>
            <p>Your payment was successful.</p>
            <router-link to="/" class="btn btn-light mt-5">Back to Homepage</router-link>
        </div>
    </Layout>
</template>